<template>
  <NavBar />
  <notifications position="top right" />
  <div class="flex items-center justify-center h-screen" v-if="loading">
    <!-- Substitua a linha abaixo pelo código do GIF animado -->
    <img src="img/loading.gif" alt="Carregando..." />
  </div>

  <router-view />

  <FooterView class="mt-10" />
</template>
<script>
import NavBar from '@/components/Default/NavBar.vue'
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import FooterView from '@/components/Default/Footer.vue'
export default {
  name: 'AppView',
  data() {
    return {
      home: 'Home',
      color: '#008BFC'
    }
  },
  components: {
    NavBar,
    FooterView,
  //  PulseLoader,
  },

  mounted() {
    document.title = "Poptudo - Tudo, tudo mesmo na palma da mão!"
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    }
  },

}
</script>

  

