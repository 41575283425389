export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torna as compras online de alimentos, mercearias, entrega de encomendas e farmácias rápidas e fáceis. Receba mantimentos e peça suas comidas favoritas dos melhores fornecedores."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POPTUDO é Nilópolis na palma da mão!"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os produtos e perviços. Compre comida, roupas, eletrônicos, remédios, peça entregador ou viagem de carro/moto. Tudo com preço menor que iFood, Uber e outros apps porque cobramos taxas menores aos vendedores, motoristas, etc."])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra fornecedores locais que entregam na sua porta"])},
  "landingpageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganhe mais no Poptudo"])},
  "landingpageJoinSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu produto ou serviço visto por toda Nilópolis! Aumente suas vendas online e fature mais com nossa comissão 10% MENOR que iFood, Uber, 99 e qualquer outro. Gerencie sua empresa pelo nosso gestor online no pc/notebook ou use o app gestor. "])},
  "PagtudoBlocoTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu dinheiro vale 5% MAIS"])},
  "PagtudoBlocoSubTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GANHE NA HORA 5% em qualquer valor colocado na nossa carteira PagTudo. Pagtudo é mais valioso que o Real e + seguro que PIX. Aqui seu dinheiro vale mais que no banco. "])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne-se parceiro!"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Registre-se como vendedor e abra uma loja para iniciar seu negócio hoje mesmo."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várias formas de pagamento"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestor de pedidos fácil"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente usa Pix, Pag.online e Carteira PagTudo onde ele já ganha 5% na hora."])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fácil para gerenciar e acompanhar seus pedidos e seus serviços"])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne-se um motorista/entregador conosco e ganhe mais."])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se à nossa equipe como motorista/entregador  e libere seu potencial de ganhos. Aproveite a liberdade de horários flexíveis e salários competitivos enquanto proporciona felicidade aos clientes."])},
    "landingpageRiderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne-se motorista/entregador"])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre agora no app Poptudo! "])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E encontre todos os produtos/serviços de Nilópolis. Garantimos preços menores que iFood, Uber e outros apps porque nossas taxas são menores para os vendedores e prestadores de serviços."])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contate-nos"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidade"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos e Condições"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça negócios conosco"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quer ganhar com Entrega ou Viagens?"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quer seu produto/serviço no"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar conta"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre uma loja"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["direito autoral"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todos os direitos reservados"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizamos cookies para melhorar a sua experiência no nosso site. Ao navegar neste site, você concorda com o uso de cookies."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte-se"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais vendido"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O mais novo"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apresentou"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar produtos"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar Resultados"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados da busca por"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado encontrado"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado encontrado para"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver tudo"])},
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver mais"])},
    "seeless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostre menos"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecedores próximos"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para você"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecedores populares"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre os melhores fornecedores em sua área"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temos todos os seus fornecedores favoritos aqui"])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novas chegadas"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova chegada"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas Relâmpago"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venda rápida"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livros"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livro"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolhas de hoje"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum fornecedor"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher arquivo"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços populares"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais votado"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em estoque"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fora de estoque"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best-seller"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quente"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao carrinho"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado para você"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções"])},
    "sameVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produtos do mesmo fornecedor"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione opções para adicioná-los ao produto"])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu carrinho"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço total do produto"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluir a compra"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu carrinho está vazio"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu carrinho está vazio. Comece a comprar agora."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há extras disponíveis"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resumo do pedido"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este subtotal não inclui o custo de entrega ou retirada"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de itens"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconto"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconto"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante total"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ter uma conta?"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use um código de desconto"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obter desconto"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras com criptografia SSL"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participar"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para um checkout mais tranquilo"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para finalização"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
    "passwordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua senha deve incluir pelo menos 8 caracteres, 1 maiúscula, 1 letra minúscula e 1 número"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu sua senha?"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou senha está incorreto"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não tem uma conta?"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já tem uma conta?"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma conta"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo de volta ao Poptudo!"])},
    "SignUpLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie a sua conta aqui"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "checkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao criar uma conta você concorda com nossa Política de Privacidade e Termos e Condições"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome completo"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecho eclair"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme sua senha"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça o que quiser das suas lojas favoritas de Nilópolis"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido de retirada"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique se você irá retirar o pedido do fornecedor"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor selecione o endereço de entrega"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meios de Pagamento"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer solicitação de pedido"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de entrega"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar endereço"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "orderReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O pedido estará pronto em"])},
  "OrderTaxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedir táxi"])},
  "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Veículo"])},
  "parcel": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encomende uma encomenda para recolha hoje"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quem pagar?"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remetente"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptor"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envie encomendas ou pacotes para amigos e familiares."])},
    "modalTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações sobre destinatários e parâmetros do pacote"])},
    "modalSubTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatários do pacote"])},
    "modalSubTitile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros do pacote"])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastreie sua encomenda"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça agora"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pedidos recentes"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar pacotes"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu serviço de correio local sob demanda"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar tudo"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de pacote"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um tipo de pacote"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecedores de correio"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um fornecedor de correio"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de entrega"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione coleta e destino aqui"])},
    "fromLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "stopLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para onde ..."])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um local de coleta"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um local de entrega"])},
    "addStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar paradas"])},
    "chooseTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um tipo de pacote"])},
    "choosesCoourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um mensageiro"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatários"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do destinatário"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de contato"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao confirmar que aceito, este pedido não contém itens ilegais/restritos, se itens ilegais/restritos forem encontrados por"])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parceiro, eles poderão denunciá-lo às autoridades responsáveis pela aplicação da lei. Aplicam-se termos e condições."])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros do pacote"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preencha todos os parâmetros obrigatórios do pacote aqui"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimento"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largura"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da Entrega"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desistências"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distância"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de entrega"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de tipo de pacote"])},
    "recipientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatário"])},
    "recipientEmptyName ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome do destinatário é obrigatório"])},
    "recipientEmptyPhone ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de telefone do destinatário é obrigatório"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como você gostaria de receber seu pedido?"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu pedido"])},
    "proceedToChceckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluir a compra"])},
    "acceptedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Aceitamos as seguintes opções de pagamento"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de entrega"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gorjeta do motorista"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer Pedido"])}
  },
  "orders": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus Pedidos"])},
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar conta"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você ainda não fez um pedido conosco. Quando você fizer isso, eles aparecerão aqui."])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produtos"])},
    "status": {
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendente"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
      "enroute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A caminho"])},
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processando"])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
      "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregue"])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto"])},
      "preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparando"])}
    }
  },
  "statuses": {
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendente"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
    "enroute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A caminho"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processando"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
    "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregue"])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto"])},
    "preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparando"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações pessoais"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olá"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudança de senha"])},
    "myFavourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus favoritos"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha Antiga"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Senha"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme sua senha"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar senha"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereços"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar perfil"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem endereço"])}
  }
}